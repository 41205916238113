import { CONTENT_URL } from "../constants";
import { apliSlice } from "./apiSlice";

export const contentSlice = apliSlice.injectEndpoints({
  endpoints: (builder) => ({
    allContents: builder.query({
      query: () => `${CONTENT_URL}/allcontents`,
    }),

    getContentById: builder.query({
      query: (contentId) => `${CONTENT_URL}/${contentId}`,
      providesTags: (result, error, contentId) => [
        { type: "Content", id: contentId },
      ],
    }),
    getNewContents: builder.query({
      query: () => `${CONTENT_URL}/newcontents`,
    }),

    getFilteredContents: builder.query({
      query: ({ checked }) => ({
        url: `${CONTENT_URL}/filtercontents`,
        method: "POST",
        body: { checked },
      }),
    }),

    getDateContent: builder.query({
      query: () => `${CONTENT_URL}/getcontent`,
    }),
    getArchivesContent: builder.query({
      query: () => `${CONTENT_URL}/getarchives`,
    }),
    getCodContent: builder.query({
      query: () => `${CONTENT_URL}/getpreviouscontent`,
    }),
  }),
});

export const {
  useAllContentsQuery,
  useGetContentByIdQuery,
  useGetFilteredContentsQuery,
  useGetNewContentsQuery,
  useGetArchivesContentQuery,
  useGetDateContentQuery,
  useGetCodContentQuery,
} = contentSlice;
